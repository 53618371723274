<script>
  export default {
    props: ["news"],
    data: function() {
      return {
        dummy: `https://new-web-site-bucket.s3.amazonaws.com/news/notice_empty.jpg`,
        extensions: ["mov", "mp4", "wmv", "avi", "avchd", "mkv"],
        loaded: false,
      };
    },
    computed: {
      poster() {
        return this.getPoster(this.news);
      },
      showBtnPlay() {
        return this.extensions.includes(this.cutName(this.poster));
      },
    },
    methods: {
      cutName(url) {
        const _url = url.split(".");
        return _url.length > 1 ? _url.pop().replaceAll("%20", " ") : _url;
      },
      convertToJSON(location) {
        return JSON.stringify({ location });
      },
      getPoster: function(news) {
        try {
          if (news?.thumbnail) {
            return news.thumbnail;
          }
          if (!news?.imageNews) {
            return this.dummy;
          }
          const typeImage = Array.isArray(news.imageNews) ? "array" : typeof news?.imageNews;
          const dicValidByType = {
            array: this.posterArray,
            string: this.posterString,
          };
          const newsValidate = dicValidByType?.[typeImage]?.(news);
          return this.getUrlPoster(newsValidate);
        } catch (error) {
          console.log(error);
          return this.dummy;
        }
      },
      posterArray(news) {
        const firstImageLocation = news?.imageNews?.[0];
        if (!firstImageLocation) {
          return;
        }
        if (!firstImageLocation.startsWith("{") || !firstImageLocation.endsWith("}")) {
          return this.convertToJSON(firstImageLocation);
        }
        return this.convertToJSON(JSON.parse(firstImageLocation).location);
      },
      posterString(news) {
        return this.convertToJSON(news.imageNews);
      },
      getUrlPoster(imageNews) {
        const imageLocation = JSON.parse(imageNews).location;
        return imageLocation || this.dummy;
      },
      goViewNews: function() {
        this.$store.state.news.NewsVisor = this.news;
        this.$router.push({ query: { news: this.news._id } }).catch(() => {});
        this.$emit("news");
      },
      loadImage: function() {
        const image = new Image();
        const imageToShow = this.showBtnPlay ? this.dummy : this.poster;
        image.src = imageToShow;
        image.onload = () => {
          this.loaded = true;
          this.$emit("onload");
        };
      },
    },
  };
</script>
<template>
  <div v-if="news" :class="`news-card-poster`" @click="goViewNews" v-bind="loadImage({})">
    <img v-if="loaded" :src="poster" alt="img" class="news-card-poster-image" />
    <img v-else :src="dummy" alt="image" class="news-card-poster-image" />
    <div v-if="news.reminder" class="reminder">Memories</div>
    <div class="slides-total" v-if="news.imageNews.length > 1 && typeof news.imageNews !== 'string'">{{ 1 }}/{{ typeof news.imageNews !== "string" ? news.imageNews.length : 1 }}</div>
    <div class="news-card-poster-play" transition v-if="showBtnPlay">
      <iconic class="play" name="play" />
    </div>
    <div :class="`news-card-poster-overlay`" transition v-else>
      <iconic name="eye" />
    </div>
  </div>
</template>

<style lang="scss">
  .news-card {
    &-poster {
      display: flex;
      justify-content: center;
      position: relative;
      height: 256px;
      overflow: hidden;

      .news-card-poster-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        left: 0;
      }

      .reminder {
        background-color: #de4ad4;
        color: #fff;
        transform: rotate(-55deg);
        text-align: center;
        width: 300px;
        height: 25px;
        margin-top: 7%;
        margin-left: -87%;
        overflow: hidden;
        position: absolute;
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      }
      .slides-total {
        position: absolute;
        color: #fff;
        top: 5px;
        right: 15px;
        font-size: 16px;
        margin-left: 85%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        padding: 1px 8px;
        border-radius: 12px;
      }
      &-overlay {
        position: absolute;
        font-size: 250%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        color: #fff;
        width: 100%;
        height: 100%;
        @include Flex(center, center, center);
        opacity: 0;
      }
    }
    &-item {
      position: relative;
      top: 92%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      @include Flex(center, center, center);
      margin-right: 5px;
      opacity: 0;
    }
    &-poster-play {
      width: 85px;
      height: 85px;
      background-color: transparent;
      border-radius: 50%;
      margin-top: 20%;
      border: 1px solid #fff;
      .play {
        font-size: 350%;
        color: #fff;
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    @media (max-width: 480px) {
      &-poster {
        height: 165px;
        .slides-total {
          margin-left: 68%;
        }
      }
      .card {
        height: 300px;
      }
    }
  }
</style>
